<template>
  <div class="row m-0 fixed">
    <div class="col-10 col-sm-6 col-md-5 col-lg-5 col-xl-4 col-xxl-3 p-4 card_item position-relative">
      <ios-close-icon class="inline-icon position-absolute position_absolute cursor" @click="close" />
      <div class="row mx-0">
        <div class="col-12 mb-3">
          We need to verify you're a human
        </div>
        <div class="col-12 mb-3 text-center">
          <div class="cf-turnstile" id="theTurnstile"></div>
          <div class="spinner-border spinner-border-sm" role="status" v-if="isTurnstileLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="col-12 mb-3">
          <Button color="red" width="100" size="smallmobile" btnText="Verify" icon="arrow" :disabled="isDisabled" :isLoading="isLoading">
            <IconArrowForward color="white" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent, ref } from 'vue'

export default defineComponent({
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  emits: ['close', 'verify'],
  setup (props, { emit }) {
    const sitekey = ref(process.env.VUE_APP_CLOUDFLARE_CAPTCHA_SITE_KEY)
    const theValue = ref(null)
    const isDisabled = ref(true)
    const isLoading = ref(false)
    const isTurnstileLoading = ref(true)
    const widgetId = ref(null)

    window.onloadTurnstileCallback = function () {
      renderTurnstile()
    }

    function setTurnstileScript () {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute(
        'src',
        '//challenges.cloudflare.com/turnstile/v0/api.js?render=explicitrender=explicit&onload=onloadTurnstileCallback'
      )
      recaptchaScript.setAttribute(
        'id',
        'turnstileScript'
      )
      document.head.appendChild(recaptchaScript)
    }

    function renderTurnstile () {
      widgetId.value = window.turnstile.render('#theTurnstile', {
        sitekey: sitekey.value,
        callback: function (token) {
          isLoading.value = true
          isDisabled.value = false
          emit('verify', token)
        }
      })
    }

    function close () {
      window.turnstile.remove(widgetId.value)
      emit('close')
    }

    return {
      sitekey,
      theValue,
      isDisabled,
      isLoading,
      isTurnstileLoading,
      widgetId,
      setTurnstileScript,
      renderTurnstile,
      close
    }
  },
  name: 'CaptchaPopup',
  async mounted () {
    this.isTurnstileLoading = true
    await this.setTurnstileScript()
    this.isTurnstileLoading = false
  }
})
</script>

<style scoped>
.fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  justify-content: center;
  align-items: center;
}
.position_absolute {
  top: 0.5rem;
  right: 0.5rem;
}
.card_item {
  background-color: #fff;
  border-radius: 13px;
}
</style>
